.nav {
  box-shadow: 0 3px 6px #00000029;
}

.button {
  > svg {
    opacity: 80%;
    transition: transform 100ms ease-out, opacity 100ms ease-out;
    transform: scale(0.8);
  }

  &.buttonActive,
  &:hover {
    > svg {
      opacity: 100%;
      transform: scale(1);
    }
  }
}
